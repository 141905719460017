<template>
  <div :key="idGroup">
    <toastification-loading-content ref="loadingToast" />

    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <b-container fluid v-if="selectedCenter">
      <b-card class="m-1 mt-2">
      <b-row class="m-1">
         <b-button
                class="m-1"
                variant="outline-secondary"
                @click="getBackToGroup()"
                :disabled="assigningFlag"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
              <b-card-title class="text-lowercase mt-2">{{ groupName }}</b-card-title>

              <b-button
                class="m-1"
                variant="secondary"
                @click="setAllCameraAllCenterToGroup()"
                :disabled="assigningFlag"
              >
                <span class="text-nowrap text-capitalize">
                 {{ $t("group_cameras.assign_all") }}
                </span>
              </b-button>
              <b-button
                class="m-1"
                variant="secondary"
                @click="deleteAllCameraAllCenterToGroup()"
                :disabled="assigningFlag"
              >
                <span class="text-nowrap text-capitalize">
                  {{ $t("group_cameras.remove_all") }}
                </span>
              </b-button>
              <b-button
                class="m-1"
                variant="secondary"
                @click="setAllCenterCamerasToGroup()"
                :disabled="assigningFlag"
              >
                <span class="text-nowrap text-capitalize">
                 {{ $t("group_cameras.assign_all_center") }}
                </span>
              </b-button>
              <b-button
                class="m-1"
                variant="secondary"
                @click="deleteAllCenterCamerasToGroup()"
                :disabled="assigningFlag"
              >
                <span class="text-nowrap text-capitalize">
                 {{ $t("group_cameras.remove_all_center") }}
                </span>
              </b-button>
      </b-row>
      <b-row cols="2" class="m-1">
        <b-col md="1">
          <b-row cols="1" v-for="center in center_and_sectors" :key="center.id">
            <b-button
              class="mb-1 mx-1"
              variant="secondary"
              @click="selectCenter(center)"
            >
              <span class="text-capitalize btn-space">
                {{center.name}} </br>
                 {{ center.total_assigned_cameras}} / {{ center.total_visible_cameras }}
              </span>
            </b-button>
          </b-row>
        </b-col>

        <b-col md="11">
          <!-- Table Container Card -->
          <b-card no-body class="ml-2">
            <b-tabs :key="renderCount">
              <b-tab
                :title="sector.name"
                :active="index == 0 ? true : false"
                v-for="(sector, index) in selectedCenter.sectors"
                :key="sector.id"
                lazy
              >
                <group-table
                  :idSector="sector.id"
                  :idGroup="idGroup"
                  :showLoading="showloading"
                  :closeLoading="closeLoading"
                  :assigningFlag="assigningFlag"
                  :disableEnableButtons="disableEnableButtons"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
        </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BContainer,
  BAvatar,
  BLink,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, watch, computed } from "@vue/composition-api";
import useList from "./useList";
import GroupTable from "./GroupTable.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import store from "@/store";
import router from "@/router";
import { avatarText } from "@core/utils/filter";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";


export default {
  components: {
    GroupTable,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,
    BContainer,
    BLink,
    BTabs,
    BTab,
    vSelect,
    ToastificationLoadingContent,
    router,
    Breadcrumb
  },
  setup(_, context) {

    if(!router.currentRoute.params.id_group){
      router.push({name: 'group'});
      return
    }

    const { t } = useI18nUtils();

    const toast = useToast();

    const { tableColumns } = useList();

    const selectedCenter = ref(null);

    const assigningFlag = ref(false);

    const idGroup = ref(parseInt(router.currentRoute.params.id_group));

    const groupName = new String(router.currentRoute.params.name);

    const center_and_sectors = ref([]);

    const loadingToast = ref(null);

    const itemFilter = ref();

    const nameNav = ref("camera.title");

    const searchQuery = ref('');

    store
      .dispatch("group_cameras/getAll", idGroup.value)
      .then(() => {
        center_and_sectors.value = store.getters["group_cameras/getItems"];
        selectedCenter.value = { ...center_and_sectors.value[0] };
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    watch(() => store.getters["group_cameras/getItems"], newItems => {
        center_and_sectors.value = newItems;
    });

    const selectCenter = (center) => {
      selectedCenter.value = { ...center };
    };

    const getBackToGroup = () => {
      router.push({ name: "group" });
    };

    const setAllCenterCamerasToGroup = () => {
      assigningFlag.value = true
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );

      store
        .dispatch("group_cameras/setAllCameraCenterToGroup", {
          id_group: idGroup.value,
          id_center: selectedCenter.value.id,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("group_cameras/getAll", idGroup.value)
          renderComponent();
        })
        .catch((result) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

    const deleteAllCenterCamerasToGroup = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );
      store
        .dispatch("group_cameras/deleteAllCameraCenterToGroup", {
          id_group: idGroup.value,
          id_center: selectedCenter.value.id,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("group_cameras/getAll", idGroup.value)
          renderComponent();
        })
        .catch((result) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

      const setAllCameraAllCenterToGroup = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );

      store
        .dispatch("group_cameras/setAllCameraAllCenterToGroup", {
          id_group: idGroup.value,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });

          store.dispatch("group_cameras/getAll", idGroup.value)
          renderComponent();
        })
        .catch((result) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };


    const deleteAllCameraAllCenterToGroup = () => {
      assigningFlag.value = true;
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );

      store
        .dispatch("group_cameras/deleteAllCameraAllCenterToGroup", {
          id_group: idGroup.value,
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: result.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          store.dispatch("group_cameras/getAll", idGroup.value)
          renderComponent();
        })
        .catch((result) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: result.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          assigningFlag.value = false;
          loadingToast.value.close();
        });
    };

    const renderCount = ref(0);

    const renderComponent = () => {
      renderCount.value++;
    };

    const showloading = () => {
      loadingToast.value.show(
        "Cámaras",
        "Actualizando Cámaras...",
        "secondary"
      );
    };

    const closeLoading = () => {
      loadingToast.value.close();
    };

    const disableEnableButtons = (assigningValue) => {
      assigningFlag.value = assigningValue
    };



    const addItemFilter = (data) => {
      itemFilter.value = data;
    };

    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };


    return {
      tableColumns,
      idGroup,
      groupName,
      center_and_sectors,
      selectedCenter,
      selectCenter,
      getBackToGroup,
      setAllCenterCamerasToGroup,
      deleteAllCenterCamerasToGroup,
      setAllCameraAllCenterToGroup,
      deleteAllCameraAllCenterToGroup,
      renderComponent,
      renderCount,
      loadingToast,
      showloading,
      closeLoading,
      assigningFlag,
      disableEnableButtons,
      nameNav,
      searchQuery,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-space {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
</style>